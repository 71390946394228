import React, { useRef, useLayoutEffect } from "react"
import { 
    motion, 
    // useViewportScroll, 
    // useTransform 
} from "framer-motion";
import { useInView } from "react-intersection-observer";
import Heading from "./heading";
import { StaticImage } from "gatsby-plugin-image"
// import useScrollPercentage from "../../../hooks/useScrollPercentage";

const HeroInner = () => {

    // useLayoutEffect(()=>{
    //     console.log(document.documentElement.scrollHeight) // 10616
    // }, [])

    // const scrollPercentage = useScrollPercentage()
    // const { scrollYProgress } = useViewportScroll()
    // const xLeft = useTransform(scrollYProgress, x => x * -10616/2.5, {ease: false})
    // const xRight = useTransform(scrollYProgress, x => x * 10616/2.5, {ease: false})
    // const yTop = useTransform(scrollYProgress, y => {
    //     // console.log(y * -3000)
    //     return (y * -10616/1.2) < -420 ? -420 : y * -10616/1.2;
    // }, {ease: false})
    // const yBottom = useTransform(scrollYProgress, y => y * 10616/2.5, {ease: false})

    const [heroRef, heroInView] = useInView()
    const [subHeadlineRef] = useInView({threshold: 0.8})
    const [captionRef] = useInView()
    const [highlightRef, highlightInView] = useInView({
        root: null,
        rootMargin: "500px 0px 0px 0px",
        threshold: 0.01,
        // threshold: 0.5
    });

    const highlightBoxRef = useRef(null)
    const rotateVariant = {
        animate: {
            rotate: [0, 360],
            transition: {
                rotate: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    type: 'spring'
                },
            },
        },
    };
    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        alt: "",
        placeholder: "none",
        className: "h-full",
        transformOptions: { fit: "cover" }
    }
    const videoRef = useRef();

    useLayoutEffect(() => { 
        videoRef.current.play(); 
    },[]);

    return(
        <section id="hero" ref={heroRef}>

            <div className="relative z-20 w-full overflow-hidden bg-white">
                <div className="container mx-auto pt-32 pb-16 lg:py-24 px-5">
                    <Heading/>
                </div>
            </div>

            <div className="relative w-full overflow-hidden">
                <motion.div 
                    className={`absolute top-0 h-full ${heroInView ? 'visible':'invisible'} right-0 left-0 z-10 will-change-transform`}
                    // style={{ y: yTop }}
                >
                    <div className={`relative w-full h-full bg-white`}>
                        <motion.div 
                            className="w-[0vw] md:w-[30vw] bg-white h-full absolute -left-5 md:-left-20 lg:left-0 top-0 bottom-0 z-10 will-change-transform" 
                            // style={{ x: xLeft }}
                        ></motion.div>
                        <motion.div
                            className={`relative w-full md:h-screen ${highlightInView ? '':'mx-auto'} ${heroInView ? "transition-all duration-[600ms] ease-in-out":""} will-change-transform`}
                            // style={{
                            //     y: highlightInView ? highlightBoxRef.current.offsetTop : 0, 
                            //     x : highlightInView ? highlightBoxRef.current.offsetLeft : 0,
                            //     width: highlightInView ? highlightBoxRef.current.clientWidth : '100%',
                            //     height: highlightInView ? highlightBoxRef.current.clientHeight : '100%',
                            // }}
                        >
                            <video 
                                className={`${highlightInView ? 'h-full':'h-96 md:h-screen filter brightness-75'} w-full object-cover absolute top-0 left-0 transition-opacity duration-300 ease-out z-[5]`}
                                ref={videoRef}
                                autoPlay
                                playsInline
                                muted
                                loop
                            >
                                <source src="/hero-mid.mp4" type="video/mp4"/>
                            </video>
                            <div className={`absolute top-0 left-0 w-full h-full z-[4] transition-opacity duration-300 ease-out`}>
                                <StaticImage src="../../../images/hero/hero-7.png" {...imageProps}></StaticImage>
                            </div>
                            {/* <img 
                                src="/hero-mid-1.png" 
                                className={`${highlightInView ? 'h-full':'h-screen filter brightness-75'} w-full object-cover will-change-transform`}
                                style={{ objectPosition: highlightInView ? '50% 23%': '50% 50%' }}
                                alt=""
                            /> */}
                        </motion.div>
                        <motion.div 
                            className="w-[0vw] md:w-[30vw] bg-white md:h-full absolute -right-5 md:-right-20 lg:right-0 top-0 z-10" 
                            // style={{ x: xRight }}
                        ></motion.div>
                    </div>
                </motion.div>

                <div className={`relative z-20 h-96 md:h-screen w-full ${heroInView ? 'visible':'invisible'}`}>
                    <div className="absolute top-0 -right-40 -z-1 hidden lg:block">
                        <div className="border-[3px] border-primary rounded-full w-72 h-72 xl:w-96 xl:h-96"></div>
                    </div>
                    <div className="h-full w-full">
                        <motion.div 
                            className="left-[-15vw] lg:left-[7.5vw] absolute bottom-0 top-0 h-screen will-change-transform" 
                            // style={{ x: xLeft, y: yBottom }}
                        >
                            <div className="hidden md:flex w-[30vw] lg:w-[20vw] h-full flex-col justify-center">
                                <div className="w-full h-[50vh] lg:h-[20vw] mb-5"><StaticImage src="../../../images/hero/hero-2.png" {...imageProps}></StaticImage></div>
                                <div className="w-full lg:h-[20vw]"><StaticImage src="../../../images/hero/hero-3.png" {...imageProps}></StaticImage></div>
                            </div>
                        </motion.div>
                        <motion.div 
                            className="right-[-15vw] lg:right-[7.5vw] absolute bottom-0 top-0 h-screen will-change-transform" 
                            // style={{ x: xRight, y: yBottom }}
                        >
                            <div className="hidden md:flex w-[30vw] lg:w-[20vw] h-full flex-col justify-center">
                                <div className="w-full h-[50vh] lg:h-[20vw] mb-5"><StaticImage src="../../../images/hero/hero-5.png" {...imageProps}></StaticImage></div>
                                <div className="w-full lg:h-[20vw]"><StaticImage src="../../../images/hero/hero-4.png" {...imageProps}></StaticImage></div>
                            </div>
                        </motion.div>
                        <motion.div 
                            className="hidden md:block lg:left-[-10vw] absolute bottom-0 top-0 h-screen" 
                            // style={{ x: xLeft, y: yBottom }}
                        >
                            <div className="w-[15vw] h-full flex flex-col justify-center">
                                <div className="h-[50vh] w-full">
                                    <StaticImage src="../../../images/hero/hero-1.png" {...imageProps}></StaticImage>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div 
                            className="hidden md:block lg:right-[-10vw] absolute bottom-0 top-0 h-screen" 
                            // style={{ x: xRight, y: yBottom }}
                        >
                            <div className="w-[15vw] h-full flex flex-col justify-center">
                                <div className="h-[50vh] w-full"><StaticImage src="../../../images/hero/hero-6.png" {...imageProps}></StaticImage></div>
                            </div>
                        </motion.div>
                    </div>
                </div>

                <div className={`relative z-40 w-full md:mt-16 py-24 lg:py-36 2xl:py-56 transition-basic`} ref={subHeadlineRef}>
                    <div className="w-full h-full absolute top-0 -z-[1]">
                        <img 
                            src="/hero-mid-1.png" 
                            className={`w-full h-full object-cover`}
                            // style={{ objectPosition: highlightInView ? '50% 23%': '50% 50%' }}
                            alt=""
                        />
                    </div>
                    <div className="flex items-center justify-center text-center px-24">
                        <h2 className="text-5xl md:text-6xl text-white leading-[3.5rem] md:leading-8 tracking-normal uppercase">
                            The Pinnacle <br/>
                            <span className="text-[105px] md:text-[152px] leading-[3rem] md:leading-[132px] font-italianno text-secondary lowercase">lifestyle </span><br/>
                            Destination 
                        </h2>
                    </div>
                    <div className={`relative z-40 w-full`} ref={captionRef}>
                        <div className="flex flex-col items-center text-center w-2/3 md:w-2/5 h-full mx-auto mt-8">
                            <p className="text-white">
                                Set to become a bustling entertainment and dining centre, Central@Gurney spans the 
                                ground and first floor beneath the newly refurbished <span className="whitespace-nowrap">G Hotel Gurney</span> lobby, which occupies 
                                the third floor. Enriched with a plethora of culinary and leisure options, Central@Gurney is 
                                the perfect backdrop for relaxation, connection and comfort. 
                            </p>
                        </div>
                    </div>
                </div>

                <div className={`h-[700px] relative z-30`} ref={highlightRef}>
                    <div className={`w-full h-full`}>
                        <div className="flex flex-col items-center justify-center h-full">
                            <div className="w-full lg:w-[968px] xl:w-[1200px] px-4 mx-auto mb-3 md:mb-5">
                                <div className="md:flex flex-row flex-wrap">
                                    <h2 className="text-5xl md:text-7xl xl:text-[80px] uppercase text-left break-words">Transforming</h2>
                                    <div 
                                        className="md:flex-1 w-full h-[70px] md:ml-10 mt-3" 
                                        ref={highlightBoxRef}
                                    >
                                        <img 
                                            src="/hero-mid-1.png" 
                                            className={`h-full w-full object-cover`}
                                            style={{ objectPosition: '50% 23%'}}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-[968px] xl:w-[1200px] px-4 mx-auto mb-3 md:mb-5">
                                <div className="flex flex-col md:flex-row-reverse">
                                    <h2 className="text-5xl md:text-7xl xl:text-[80px] uppercase text-left md:text-right">The way people</h2>
                                    <div className="md:flex-1 bg-gray-200 w-full h-[70px] mr-10 mt-3">
                                        <img src="/highlight-3.png" className="w-full h-full object-cover" style={{ objectPosition: '50% 95%' }} alt=""></img>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-[968px] xl:w-[1200px] px-4 mx-auto">
                                <div className="flex flex-row flex-wrap md:justify-between items-center gap-3 md:gap-0">
                                    <h2 className="text-5xl md:text-7xl xl:text-[80px] uppercase text-left">Meet</h2>
                                    <motion.div 
                                        className={`w-12 h-12 md:w-20 md:h-20 rounded-full ${highlightInView ? 'transition-all duration-300':''}"`}
                                        style={{
                                            transform: highlightInView ? 'scale(1)':'scale(0)'
                                        }}
                                    >
                                        <StaticImage src="../../../images/highlight/highlight-1.png" {...imageProps}></StaticImage>
                                    </motion.div>
                                    <h2 className="text-5xl md:text-7xl xl:text-[80px] uppercase text-left">Dine</h2>
                                    <motion.div 
                                        className="w-12 h-12 md:w-20 md:h-20 rounded-full"
                                        variants={rotateVariant}
                                        animate="animate"
                                    >
                                        <StaticImage src="../../../images/highlight/highlight-2.png" {...imageProps}></StaticImage>
                                    </motion.div>
                                    <h2 className="text-5xl md:text-7xl xl:text-[80px] uppercase text-left">Chill out</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}

const Hero = () => {
    return(
        <HeroInner/>
    )
  
}

export default Hero
