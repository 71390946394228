import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Hero from "../components/sections/hero"
import Location from "../components/sections/location"
import About from "../components/sections/about"
import Plan from "../components/sections/plan"
// import Company from "../components/sections/company"
import Contact from "../components/sections/contact"
import Progress from "../components/sections/progress"
import Promotion from "../components/sections/promotion"

const IndexPage = () => (
  <Layout>
    <Seo title="Central@Gurney" />

    {
      typeof window !== 'undefined' && 
        <>
          <Hero/>
          <About/>
          <Location/>
          <Promotion/>
          <Plan/>
          {/* <Company/> */}
          <Progress/>
          <Contact/>
        </>
    }

  </Layout>
)

export default IndexPage
