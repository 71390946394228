import React, { useEffect, useState, useMemo } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";


import 'swiper/css';

const Promotion = () => {

    const imageProps = {
        layout:"fullWidth",
        quality:45,
        formats:["auto", "webp", "avif"],
        alt:"",
        placeholder:"none",
        transformOptions:{ fit: 'contain' },
    }

    const [modal, setModal] = useState(0)
    const [caption, setCaption] = useState("")

    const PromoImages = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/promotion/promotion-1-holy.png`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/promotion/promotion-2-theloft.png`} alt={item} {...imageProps}/>
            case 3:
                return <StaticImage src={`../../images/promotion/promotion-3-halzan.jpg`} alt={item} {...imageProps}/>
            case 4:
                return <StaticImage src={`../../images/promotion/promotion-4-richiamo.jpeg`} alt={item} {...imageProps}/>
            case 5:
                return <StaticImage src={`../../images/promotion/promotion-5-kanada.jpg`} alt={item} {...imageProps}/>
            case 6:
                return <StaticImage src={`../../images/promotion/promotion-6-coffebean.jpg`} alt={item} {...imageProps}/>
            case 7:
                return <StaticImage src={`../../images/promotion/promotion-7-winter.jpg`} alt={item} {...imageProps}/>
            case 8:
                return <StaticImage src={`../../images/promotion/promotion-8-miraku.jpeg`} alt={item} {...imageProps}/>
            case 9:
                return <StaticImage src={`../../images/promotion/promotion-9-treebar.jpg`} alt={item} {...imageProps}/>
            case 10:
                return <StaticImage src={`../../images/promotion/promotion-10-antipodean.jpg`} alt={item} {...imageProps}/>
            case 11:
                return <StaticImage src={`../../images/promotion/promotion-12-chilis.jpeg`} alt={item} {...imageProps}/>
            case 12:
                return <img src="/promotion/promotion-13-minimelts.jpg" alt={item} className="w-full h-full object-cover"/>
            case 13:
                return <img src="/promotion/promotion-14-dipndip.jpg" alt={item} className="w-full h-full object-cover"/>
            default:
                return null;
        }
    }
    const sliderArray = useMemo(()=> [
        "The Holy Guacamole",
        "The Loft",
        "Halzan by Wheeler's",
        "Richiamo Coffee",
        "Kanada-Ya",
        "The Coffee Bean & Tea Leaf",
        "Winter Warmers Coffee & Tea House",
        "Miraku",
        "Tree Bar",
        "Antipodean",
        "Chilis",
        "Mini Melts",
        "Dip n Dip",
    ], [])

    const goPrev = () => {
        setModal(prev => prev <= 1 ? 8 : prev - 1)
    }

    const goNext = () => {
        setModal(prev => prev <= 8 ? prev + 1 : 1)
    }

    useEffect(()=>{
        setCaption(sliderArray[modal-1])
    }, [modal, sliderArray])

    const CloseButton = () => {
        return(
            <button 
                onClick={()=>setModal(0)}
                className="absolute top-0 right-0 flex flex-col justify-center items-center w-16 h-16 ml-auto mr-6 transition-basic z-50" >
                    <div className="transition-basic transform rotate-45 translate-y-[22px] bg-white w-2/4 h-[2px] mb-5"></div>
                    <div className="transition-basic transform -rotate-45 bg-white w-2/4 h-[2px]"></div>
            </button>
        )
    }

    return(
        <>
            <section id="promotion" className="bg-dark">
                <div className="container mx-auto pt-10">
                    <h3 className="text-white text-[40px] md:text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center mb-3">
                        Promotions 
                    </h3>
                </div>
                <Swiper
                    className="swiper-interior mb-10 mt-5"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={16}
                    slidesPerView={1.75}
                    centeredSlides
                    initialSlide={2}
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 32,
                            centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={item+i}>
                            <div className="relative md:hover:scale-[1.075] transition-basic md:my-5">
                                <button className="absolute z-50 w-full h-full" onClick={()=>setModal(i+1)}>{' '}</button>
                                <PromoImages index={i+1} item={item}/>
                            </div>
                            <div className="px-3 pt-3 md:pt-0 mb-2 text-white text-center">
                                <span className="font-normal">{item}</span>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={`modal ${modal !== 0 ? 'visible opacity-100':'invisible opacity-0'} transition-basic`}>
                    <CloseButton/>
                    <div className="bg-black bg-opacity-75 p-3 w-full h-full flex items-center justify-center" >
                        <button onClick={()=>setModal(0)} className="absolute top-0 right-0 bottom-0 left-0 -z-1 cursor-default"> </button>
                        <div className="w-11/12 lg:w-[100vh] mx-auto relative z-50">
                            <div className="">
                                <div className="w-full aspect-video">
                                    <PromoImages index={modal} item={caption}/>
                                </div>
                            </div>
                            <div className="absolute top-0 bottom-0 -left-12 md:-left-16 h-full flex items-center">
                                <button onClick={goPrev} className="text-white w-16 h-16 rounded-full flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width={45} height={45} viewBox="0 0 24 24" strokeWidth={1.25} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <polyline points="15 6 9 12 15 18"></polyline>
                                    </svg>
                                </button>
                            </div>
                            <div className="absolute top-0 bottom-0 -right-12 md:-right-16 h-full flex items-center">
                                <button onClick={goNext} className="text-white w-16 h-16 rounded-full flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={45} height={45} viewBox="0 0 24 24" strokeWidth={1.25} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <polyline points="9 6 15 12 9 18"></polyline>
                                    </svg>
                                </button>    
                            </div>
                        </div>
                    </div>    
                </div>
            </section>
        </>
    )
}

export default Promotion