import React, { useEffect, useState, useMemo } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

const Slider = () => {

    const itemProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    const [modal, setModal] = useState(0)
    const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../../images/interior/interior-10.png`} alt={item} {...itemProps}/>
            case 2:
                return <StaticImage src={`../../../images/interior/interior-6.png`} alt={item} {...itemProps}/>
            case 3:
                return <StaticImage src={`../../../images/interior/interior-9.png`} alt={item} {...itemProps}/>
            case 4:
                return <StaticImage src={`../../../images/interior/interior-15.jpg`} alt={item} {...itemProps}/>
            case 5:
                return <StaticImage src={`../../../images/interior/interior-14.jpg`} alt={item} {...itemProps}/>
            case 6:
                return <StaticImage src={`../../../images/interior/interior-16.jpg`} alt={item} {...itemProps}/>
            case 7:
                return <StaticImage src={`../../../images/interior/interior-7.png`} alt={item} {...itemProps}/>
            case 8:
                return <StaticImage src={`../../../images/interior/interior-8.png`} alt={item} {...itemProps}/>
            case 9:
                return <StaticImage src={`../../../images/interior/interior-4.png`} alt={item} {...itemProps}/>
            case 10:
                return <StaticImage src={`../../../images/interior/interior-17.jpg`} alt={item} {...itemProps}/>
            case 11:
                return <StaticImage src={`../../../images/interior/interior-18.jpg`} alt={item} {...itemProps}/>
            default:
                return <StaticImage src={`../../../images/interior/interior-12.jpg`} alt={item} {...itemProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "The combination of soft LED lighting and wooden textures creates a luxurious ambience in the open-plan layout.",
        "The wooden façade with the logo of Central@Gurney.",
        "Dining interiors are spacious and airy with high ceilings.",
        "",
        "",
        "",
        "The Tree Bar offers an al fresco spot for a celebratory meal or a chill night out.",
        "Al fresco dining at our anchor tenants, including Miraku, Coffee Bean & Tea Leaf, Winter Warmers and more to come.",
        "Food and beverage establishments at the sidewalk between Gurney Plaza and Central@Gurney.",
        "",
        "",
        "",
    ], [])

    const goPrev = () => {
        setModal(prev => prev <= 1 ? 13 : prev - 1)
    }

    const goNext = () => {
        setModal(prev => prev <= 12 ? prev + 1 : 1)
    }

    useEffect(()=>{
        setCaption(sliderArray[modal-1])
    }, [modal, sliderArray])

    // useEffect(()=>{
    //     console.log(caption)
    // },[caption])

    const CloseButton = () => {
        return(
            <button 
                onClick={()=>setModal(0)}
                className="absolute top-0 right-0 flex flex-col justify-center items-center w-16 h-16 ml-auto mr-6 transition-basic z-50" >
                    <div className="transition-basic transform rotate-45 translate-y-[22px] bg-white w-2/4 h-[2px] mb-5"></div>
                    <div className="transition-basic transform -rotate-45 bg-white w-2/4 h-[2px]"></div>
            </button>
        )
    }

    return(
        <>
            <Swiper
                className="swiper-interior"
                modules={[Navigation, Autoplay, FreeMode]}
                navigation={true}
                spaceBetween={16}
                slidesPerView={1.75}
                centeredSlides
                initialSlide={2}
                loop
                // speed={1240}
                // freeMode
                // freeModeMomentum
                // autoplay={{
                //     delay: 2400,
                // }}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 32,
                        centeredSlides: false,
                        initialSlide: 0
                    },
                }}
            >
                {sliderArray.map((item, i) => (
                    <SwiperSlide key={"interior"+item+i}>
                        <div className="aspect-video bg-gray-200 relative md:hover:scale-[1.075] md:hover:shadow transition-basic md:my-5">
                            <button className="absolute z-50 w-full h-full" onClick={()=>setModal(i+1)}>{' '}</button>
                            <InteriorImage index={i+1} item={item}/>
                            {/* {console.log(item)} */}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={`modal ${modal !== 0 ? 'visible opacity-100':'invisible opacity-0'} transition-basic`}>
                <CloseButton/>
                <div className="bg-black bg-opacity-75 p-3 w-full h-full flex items-center justify-center" >
                    <button onClick={()=>setModal(0)} className="absolute top-0 right-0 bottom-0 left-0 -z-1 cursor-default"> </button>
                    <div className="w-11/12 lg:w-[100vh] mx-auto relative z-50">
                        <div className="">
                            <div className="w-full aspect-video">
                                <InteriorImage index={modal} item={caption}/>
                            </div>
                            <div className="text-center mt-4">
                                <p className="text-white text-xs font-light tracking-wide">{caption}</p>
                            </div>
                        </div>
                        <div className="absolute top-0 bottom-0 -left-12 md:-left-16 h-full flex items-center">
                            <button onClick={goPrev} className="text-white w-16 h-16 rounded-full flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width={45} height={45} viewBox="0 0 24 24" strokeWidth={1.25} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <polyline points="15 6 9 12 15 18"></polyline>
                                </svg>
                            </button>
                        </div>
                        <div className="absolute top-0 bottom-0 -right-12 md:-right-16 h-full flex items-center">
                            <button onClick={goNext} className="text-white w-16 h-16 rounded-full flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={45} height={45} viewBox="0 0 24 24" strokeWidth={1.25} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <polyline points="9 6 15 12 9 18"></polyline>
                                </svg>
                            </button>    
                        </div>
                    </div>
                </div>    
            </div>
        </>
    )
}

export default Slider;