import React, { useLayoutEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer";
import useScrollPercentage from "../../hooks/useScrollPercentage";
// import GoogleMapReact from 'google-map-react';
import { StaticImage } from "gatsby-plugin-image"
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import { motion } from "framer-motion";

const LocationFeatures = ({title, desc, index}) => {

    const featureVariants = {
        initial: {
            opacity: 0,
            x: -20
        },
        animate: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.65,
                delay: index * 0.24,
                type: "tween"
            }
        }
    }

    return(
        <div className="flex-1 py-5 px-6 lg:pt-8 lg:pb-12 md:px-10 border-b md:border-r border-gray-200 hover:border-b-primary cursor-pointer transition-basic group">
            <motion.div
                variants={featureVariants} 
                initial="initial" 
                whileInView="animate"
                viewport={{ once: true }}
            >
                <h3 className="text-center md:text-left text-2xl font-playfair font-medium mb-1 group-hover:text-primary transition-basic">{title}</h3>
                <p className="text-center md:text-left text-sm text-gray-500 tracking-wide font-light">{desc}</p>
            </motion.div>
        </div>
    )
}

const Location = () => {

    // const { width } = useWindowDimensions()
    const mapContainerRef = useRef()
    const [mapRef] = useInView({
        root: null,
        // rootMargin: "50px 0px 300px 0px",
        threshold: 0.99
    });

    const scrollPercentage = useScrollPercentage();
    const [animateProgress, setAnimateProgress] = useState(0)

    useLayoutEffect(()=>{
        const mapContainerTopPercentage = (mapContainerRef.current.offsetTop/document.documentElement.scrollHeight) * 100
        if(scrollPercentage > mapContainerTopPercentage + 3){
            setAnimateProgress(10 + (scrollPercentage - (mapContainerTopPercentage + 3)) * 5);
        }else{
            setAnimateProgress(10);
        }
        // mapContainerTopPercentage + 2.5 (60)
        // console.log("value: "+mapContainerRef.current.offsetTop, document.documentElement.scrollTop, document.documentElement.scrollHeight)
        // console.log("scroll percentage: "+scrollPercentage)
        // console.log("map percentage: "+mapContainerTopPercentage)
    },[animateProgress, scrollPercentage, mapContainerRef])

    // const mapSettings = {
    //     center: {
    //       lat: 5.437848517740787,
    //       lng: 100.31059488586229
    //     },
    //     zoom: 17
    // };

    // const [mapBorderWidth, setMapBorderWidth] = useState(0)
    // useLayoutEffect(()=>{
    //     if(width < 568){
    //         setMapBorderWidth(animateProgress*3)
    //     }else if(width < 768){
    //         setMapBorderWidth(animateProgress*2.5)
    //     }else if(width < 992){
    //         setMapBorderWidth(animateProgress*2.1)
    //     }else if(width < 1280){
    //         setMapBorderWidth(animateProgress*1.9)
    //     }else if(width < 1400){
    //         setMapBorderWidth(animateProgress*1.8)
    //     }else{
    //         setMapBorderWidth(animateProgress*1.75)
    //     }
    // },[width, animateProgress])

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        alt: "",
        placeholder: "none",
        className: "h-full",
        transformOptions: { fit: "cover" }
    }

    const fadeInVariant = {
        initial: {
            opacity: 0,
            scale: 0.1
        },
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.6,
                delay: 0.24,
                type: "spring",
                stiffness: 100
            }
        }
    }

    return(
        <>
            <section id="location" className="reset" ref={mapContainerRef}>
                <div className="sticky top-0 w-full lg:h-screen overflow-hidden bg-white" ref={mapRef}>
                    <div className="absolute -top-[200px] -left-[150px] -z-1 hidden lg:block">
                        <div className="border-2 border-gray-100 rounded-full w-72 h-72 xl:w-[500px] xl:h-[500px]"></div>
                    </div>

                    <div className="relative z-10 lg:h-screen w-full py-8 lg:py-0">
                        <div className="lg:absolute left-0 top-28 md:left-10 md:top-10 lg:left-12 lg:top-20 mb-4 w-full h-full">
                            <h3 className="text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center lg:text-left">
                                A Bustling
                                <span className="text-primary block italic font-normal">New Address</span>
                            </h3>
                        </div>
                        <div className="lg:absolute left-8 bottom-8 right-8 md:left-10 md:top-10 lg:top-auto lg:left-auto lg:right-8 lg:bottom-[25%] justify-end flex">
                            <p className="lg:w-3/12 font-light text-[15px] tracking-wide leading-relaxed text-black flex items-center justify-center text-center lg:text-left px-8 lg:px-0">
                            Central@Gurney is just moments away from residential neighbourhoods, shopping centres, hotels and tourism offerings. Steeped in its unique charm, entice and entertain residents and visitors at this distinguished business address. 
                            </p>
                        </div>
                        <motion.div 
                            variants={fadeInVariant} 
                            initial="initial" 
                            whileInView="animate"
                            className="absolute -bottom-24 -left-8 hidden lg:block"
                        >
                            <div className="bg-white w-80 h-80 rounded-full overflow-hidden relative shadow-lg">
                                <StaticImage src="../../images/hero/hero-6.png" {...imageProps}></StaticImage>
                            </div>
                        </motion.div>
                        <motion.div 
                            variants={fadeInVariant} 
                            initial="initial" 
                            whileInView="animate"
                            className="absolute -top-20 -right-8 hidden lg:block"
                        >
                            <div className="bg-white w-80 h-80 rounded-full overflow-hidden relative shadow-lg">
                                <StaticImage src="../../images/hero/hero-8.png" {...imageProps}></StaticImage>
                            </div>
                        </motion.div>
                    </div>

                    {/* <div className="lg:absolute lg:flex hidden top-0 bottom-0 z-40 h-full w-full justify-center items-center">
                        <div 
                            style={{ 
                                width: '42%',
                                display: width > 1024 ? 'block' : 'none'
                                // width: mapBorderWidth + '%' ,
                                // opacity: mapBorderWidth > 100 ? 0 : 1
                            }} 
                            className="aspect-square rounded-full border-4 border-primary bg-white"
                        ></div>
                    </div> */}

                    <div className="lg:absolute flex top-0 bottom-0 z-40 h-full w-full justify-center items-center">
                        <div className="w-11/12 lg:w-5/12 aspect-square rounded-full overflow-hidden border-4 border-primary transform translate-z-0">
                            <StaticImage 
                                src="../../images/map.png"
                                layout="fullWidth"
                                quality={45}
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                placeholder="none"
                                className="h-full"
                            ></StaticImage>
                        </div>
                    </div>

                    {/* <div className="lg:absolute top-0 right-0 left-0 bottom-0 z-40 h-full w-full block">
                        <div 
                            className={`w-full h-screen flex items-center`}
                            style={{
                                // clipPath: 'circle('+ animateProgress +'% at 50% 50%)',
                                clipPath: width > 1024 ? 'circle(25% at 50% 50%)':'unset',
                            }}
                        >
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAP_API_KEY }}
                                defaultCenter={mapSettings.center}
                                defaultZoom={mapSettings.zoom}
                                draggable={false}
                                options={{
                                    disableDefaultUI: true,
                                    mapId: process.env.GATSBY_GOOGLE_MAP_ID
                                }}
                                // yesIWantToUseGoogleMapApiInternals
                            >
                                <div
                                    lat={5.439652502663134}
                                    lng={100.30929285002277}
                                    className="relative">
                                        <div className="w-3 h-3 bg-dark rounded-full"></div>
                                        <div className="absolute z-40 bg-dark px-4 py-2 inline-block rounded-full w-max">
                                            <p className="text-white text-[15px]">Gurney Drive Hawker</p>
                                        </div>
                                </div>
                                <div 
                                    lat={5.438412863595464}
                                    lng={100.30984189015838}
                                    className="relative">
                                        <div className="w-3 h-3 bg-dark rounded-full"></div>
                                        <div className="absolute -top-4 left-4 z-40 bg-dark px-4 py-2 inline-block rounded-full w-max">
                                            <p className="text-white text-[15px]"> Gurney Plaza</p>
                                        </div>
                                </div>
                                <div 
                                    lat={5.437848517740787}
                                    lng={100.31059488586229}
                                    className="relative">
                                        <div className="w-3 h-3 bg-primary rounded-full"></div>
                                        <div className="absolute z-40 bg-primary px-4 py-2 inline-block rounded-full w-max">
                                            <p className="text-white text-[15px]">G Hotel Gurney</p>
                                        </div>
                                        <div className="absolute left-0 top-14 bg-white px-3 py-1 inline-block rounded-full shadow-lg hover:scale-110">
                                            <a className="w-16 h-10 flex mx-auto bg-white" href="https://goo.gl/maps/FrvL9BXFMMQgPiDa6" target="_blank" rel="noreferrer">
                                                <div className="w-10/12 m-auto">
                                                    <StaticImage
                                                        src="../../images/logo.png"
                                                        layout="fullWidth"
                                                        quality={45}
                                                        formats={["auto", "webp", "avif"]}
                                                        alt=""
                                                        objectFit="contain"
                                                        placeholder="none"
                                                    />    
                                                </div>
                                            </a>
                                        </div>
                                </div>
                                <div
                                    lat={5.43596738632554}
                                    lng={100.30979094832297}
                                    className="relative">
                                        <div className="w-3 h-3 bg-dark rounded-full"></div>
                                        <div className="absolute -top-10 -left-16 z-40 bg-dark px-4 py-2 inline-block rounded-full w-max">
                                            <p className="text-white text-[15px]">G Hotel Kelawai</p>
                                        </div>
                                </div>
                                <div
                                    lat={5.436119524600106}
                                    lng={100.31089468871464}
                                    className="relative">
                                        <div className="w-3 h-3 bg-dark rounded-full"></div>
                                        <div className="absolute -top-10 z-40 bg-dark px-4 py-2 inline-block rounded-full w-max">
                                            <p className="text-white text-[15px]">Gurney Paragon Mall</p>
                                        </div>
                                </div>
                            </GoogleMapReact>
                        </div>
                    </div> */}
                </div>
            </section>
            <div className="w-full">
                <div className="relative z-10 w-full md:border-t border-gray-200">
                    <div className="md:flex flex-row items-top">
                        <LocationFeatures index={1} title="Easy Accessibility" desc="Strategically located just minutes away from neighbouring lifestyle malls such as Gurney Plaza & Gurney Paragon "/>
                        <LocationFeatures index={2} title="A Warm Welcome" desc="An array of dining options for local & international guests at G Hotel Gurney and G Hotel Kelawai on leisure & business trip"/>
                        <LocationFeatures index={3} title="Community Hub" desc="Interconnection between mid to high-end residentials within a 5 to 10 km radius "/>
                        <LocationFeatures index={4} title="Thriving Hotspot" desc="Attracting an approximate population of 1.3 million within Penang Island, comprising locals & expatriates "/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Location;


